.image-container {
    width: 100%;
}

.portfolio-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    a{
        font-size: 30px;
        font-weight: 300;
        line-height: 5cqb;
        text-decoration: none;
        color: #E34951
    }

    li {
        list-style: none;
        margin-left: -10%;
        text-align: center;
    }
}

.square {
    margin-top: 25%;
    margin-bottom: 25%;
    margin-left: 5%;
    margin-right: 5%;
    height: 300px;
    width: 300px;
    border: 4px solid #E34951;
    border-radius: 3px;
  }

