.home-page {

    .headshot {
        height: 500px;
        margin-left: 55%;
        margin-top: 8%;
        border: 10px solid #ffd700;
        border-radius: 360px;
    }

    .text-zone {
        position: absolute;
        left: 10%;
        top: 50%;
        transform: translateY(-50%);
        width: 40%;
        max-height: 90%;
    }

    h1 {
        color: #0C0C0C;
        font-size: 80px;
        margin: 0;
        font-family: 'Coolvetica';
        font-weight: 400;


        img {
            width: 32px;
            margin-left: 20px;
            opacity: 0;
            height: auto;
            animation: rotateIn 1s linear both;
            animation-delay: 1.4s;
        }
    }

    h2 {
        color: #525252;
        margin-top: 20px;
        font-weight: 400;
        font-size: 18px;
        font-family: 'Coolvetica';
        letter-spacing: 3px;
        animation: fadeIn 1s 1.8s backwards;
    }

    .flat-button {
        color: #E34951;
        font-size: 13px;
        font-weight: 400;
        letter-spacing: 4px;
        font-family: 'Coolvetica';
        text-decoration: none;
        padding: 10px 18px;
        border: 1px solid #E34951;
        margin-top: 25px;
        float: left;
        animation: fadeIn 1s 1.8s backwards;
        white-space: nowrap;

        &:hover {
            background: #E34951;
            color: #FBFBFA;
        }
    }
}