.nav-bar {
  background: #E34951;
  width: 100%;
  height: 60px;
  position: absolute;
  top: 0;
  z-index: 3;
  min-width: 500px;

  .logo {
    display: flex;
    padding: 8px 0;

    img {
      display: flex;
      margin: auto 8px;
      width: 24px;
      height: auto;

      &.sub-logo {
        width: 50px;
      }
    }
  }

    nav {
        display: flex;
        justify-content: space-evenly;
        text-align: center;
        position: absolute;
        // height: 210px;
        top: 33%;
        margin-left: 33%;
        width: 50%;

        a{
            font-size: 22px;
            color: #0C0C0C;
            display: flex;
            line-height: 50%;
            height: 100%;
            position: relative;
            text-decoration: none;
            margin-right: 33%;

            i {
                transition: all 0.3s ease-out;
            }
            
            &:hover {
                color: #FBFBFA;

                svg {
                    opacity: 0;
                }

                &:after {
                    opacity: 1;
                }
            }

            &:after {
                content: '';
                font-size: 15px;
                letter-spacing: 2px;
                position: absolute;
                bottom: 0;
                display: flex;
                width: 100%;
                text-align: center;
                opacity: 0;
                transition: all 0.3s ease-out;
            }
        &:first-child {
            &::after {
                content: 'HOME';
            }
        }
        
    }

    a.about-link {
        &::after {
            content: 'ABOUT';
        }
    }

    a.contact-link {
        &::after {
            content: 'CONTACT';
        }
    }

    a.portfolio-link {
        &::after {
            content: 'PORTFOLIO';
        }
    }

    a.active {
        svg {
            color: #ffd700
        }
    }
  }
  ul {
    position: absolute;
    bottom: 20px;
    width: 100%;
    display: flex;
    padding: 0 90%;
    list-style: none;
    text-align: center;
    margin: 0;
    margin-right: 10%;

    li {
        a {
            padding: 20px 0 0 10px;
            display: flex;
            font-size: 15px;
            line-height: 16px;

            &:hover svg {
                color: #ffd700
            }
        }
    }
  }
}
